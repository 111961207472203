<template>
  <v-container>
    <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-form :disabled="!isEditBtn" ref="form" v-model="valid">
              <v-row class="mt-10" justify="center">
                <v-col cols="12" md="10">
                  <p class="font-weight-medium title ma-0">
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.aboutYou"
                      )
                    }}
                  </p>
                  <v-row>
                    <v-col md="3">
                      <p class="mt-5">
                        {{
                          $vuetify.lang.t(
                            "$vuetify.main.psychologistDashboard.settings.yourAvatar"
                          )
                        }}
                      </p>
                    </v-col>
                    <v-col md="8">
                      <ImageInput
                        :handleDisableInputByBufferPhoto="!this.isEditBtn"
                        :handle-change-avatar="handleChangeAvatar"
                        :getAvatarElement="getAvatarElement"
                      >
                        <v-avatar size="250"
                          ><img
                            id="img"
                            :class="{ avatar__grayscale: !this.isEditBtn }"
                            :alt="
                              $vuetify.lang.t(
                                `$vuetify.main.psychologistDashboard.settings.avatarAlt`
                              )
                            "
                          />
                        </v-avatar>
                      </ImageInput>
                      <span v-if="getWarningIfAvatarChange">
                        {{
                          $vuetify.lang.t(
                            "$vuetify.main.psychologistDashboard.settings.avatarDesc"
                          )
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pt-1 pb-0">
                    <v-col md="3" class="pt-0 pb-0">
                      <span class="mt-3">{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.lastName"
                        )
                      }}</span>
                    </v-col>
                    <v-col md="4" class="pt-1 pb-0">
                      <v-text-field
                        v-model="psyhLastName.uk"
                        :rules="lastnameRules"
                        label="Прізвище"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" class="pt-1 pb-0">
                      <v-text-field
                        v-model="psyhLastName.ru"
                        :rules="lastnameRules"
                        label="Фамилия"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3" class="pt-1 pb-0">
                      <span class="mt-3">{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.name"
                        )
                      }}</span>
                    </v-col>
                    <v-col md="4" class="pt-1 pb-0">
                      <v-text-field
                        v-model="psyhName.uk"
                        :rules="nameRules"
                        label="Ім'я"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" class="pt-1 pb-0">
                      <v-text-field
                        v-model="psyhName.ru"
                        :rules="nameRules"
                        label="Имя"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3" class="pt-1 pb-0">
                      <span class="mt-3">{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.patronymic"
                        )
                      }}</span>
                    </v-col>
                    <v-col md="4" class="pt-1 pb-0">
                      <v-text-field
                        v-model="psyhPatronymic.uk"
                        label="По батькові"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" class="pt-1 pb-0">
                      <v-text-field
                        v-model="psyhPatronymic.ru"
                        label="Отчество"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <span class="mt-3">{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.timezone"
                        )
                      }}</span>
                    </v-col>
                    <v-col md="8">
                      <TimeZOneInput
                        @new-time-zone="setNewTimeZOne"
                        :default-value="timezone"
                        :isOutlined="true"
                      ></TimeZOneInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <span class="mt-3">{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.howOld"
                        )
                      }}</span>
                    </v-col>
                    <v-col md="8">
                      <v-menu
                        v-model="datePickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="date"
                            :label="
                              $vuetify.lang.t(
                                `$vuetify.main.psychologistDashboard.settings.birthday`
                              )
                            "
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="datePickerMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="3">
                      <p class="pt-3">
                        {{
                          $vuetify.lang.t(
                            "$vuetify.main.psychologistDashboard.settings.FOP"
                          )
                        }}
                      </p>
                    </v-col>
                    <v-col md="1">
                      <v-switch v-model="entrepreneur.status"></v-switch>
                    </v-col>

                    <v-col md="7">
                      <v-textarea
                        v-if="entrepreneur.status"
                        v-model="entrepreneur.requisites"
                        solo
                        name="input-7-4"
                        :label="
                          $vuetify.lang.t(
                            `$vuetify.main.psychologistDashboard.settings.FOPLabel`
                          )
                        "
                        rows="2"
                        :counter="10"
                        :rules="entrepreneurRules"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <p class="font-weight-medium title ma-0">
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.contacts"
                        )
                      }}
                    </p>
                  </v-row>

                  <v-row>
                    <v-col md="3">
                      <span class="mt-3">{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.phone"
                        )
                      }}</span>
                    </v-col>
                    <v-col md="8">
                      <vue-phone-number-input
                        :disabled="!isEditBtn"
                        v-model="userPhoneNumber"
                        :default-country-code="defaultCountry"
                        :loader="hasLoaderActive"
                        :error="hasErrorActive"
                        :preferred-countries="countriesList"
                        required
                        @update="updatedData"
                        :translations="translations"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <span class="mt-3">{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.email"
                        )
                      }}</span>
                    </v-col>
                    <v-col md="8">
                      <v-text-field
                        v-model="psyhEmail"
                        :rules="emailRules"
                        :label="
                          $vuetify.lang.t(
                            `$vuetify.main.psychologistDashboard.settings.emailLabel`
                          )
                        "
                        :placeholder="this.$store.getters['user/getUser'].email"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end mb-10">
                    <v-btn
                      @click="validate('main')"
                      v-if="isEditBtn"
                      depressed
                      color="success"
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.save"
                        )
                      }}
                    </v-btn>

                    <v-btn
                      @click="isEditBtn = !isEditBtn"
                      v-if="!isEditBtn"
                      depressed
                      color="warning"
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.update"
                        )
                      }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-form
              :disabled="!isEditBtn"
              ref="form"
              v-model="valid"
              class="mt-5 px-5"
            >
              <v-row>
                <v-col md-3>
                  <p>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.about_info"
                      )
                    }}
                  </p>
                  <p>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.updateInfo1"
                      )
                    }}<b>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.updateInfo2"
                        )
                      }}
                    </b>
                  </p>
                  <p>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.lastUpdate"
                      )
                    }}
                    {{ this.user.main_info_modified_at }}
                  </p>
                </v-col>
                <v-col md-6>
                  <v-textarea
                    :rules="[rules.required]"
                    outlined
                    name="input-7-4"
                    label="Про себе"
                    v-model="aboutPsyh.uk"
                  ></v-textarea>

                  <v-textarea
                    :rules="[rules.required]"
                    outlined
                    name="input-7-4"
                    label="О себе"
                    v-model="aboutPsyh.ru"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>

            <div class="d-flex justify-end mb-10 mr-5">
              <v-btn
                @click="validate('about')"
                v-if="isEditBtn"
                depressed
                color="success"
              >
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.settings.save"
                  )
                }}
              </v-btn>

              <v-btn
                @click="isEditBtn = !isEditBtn"
                v-if="!isEditBtn"
                depressed
                color="warning"
              >
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.settings.update"
                  )
                }}
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-form :disabled="!isEditBtn" ref="form" v-model="valid">
              <v-row class="mt-10" justify="center">
                <v-col cols="12" md="10">
                  <v-row class="mt-0">
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="university"
                        :rules="universityRules"
                        :label="
                          $vuetify.lang.t(
                            `$vuetify.main.psychologistReg.stepOne.university`
                          )
                        "
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="faculty"
                        :rules="facultyRules"
                        :label="
                          $vuetify.lang.t(
                            `$vuetify.main.psychologistReg.stepOne.faculty`
                          )
                        "
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="speciality"
                        :rules="specialityRules"
                        :label="
                          $vuetify.lang.t(
                            `$vuetify.main.psychologistReg.stepOne.speciality`
                          )
                        "
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="scienceDegree"
                        :items="scienceDegreeList"
                        item-value="id"
                        item-text="title"
                        :label="
                          $vuetify.lang.t(
                            `$vuetify.main.psychologistReg.stepOne.scienseDegree`
                          )
                        "
                        dense
                        required
                        :rules="specialityRules"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3" offset-md="2" class="mt-0 pt-0">
                      <v-text-field
                        v-model="yearOfEnding"
                        :rules="yearOfEndingRules"
                        :label="
                          $vuetify.lang.t(
                            `$vuetify.main.psychologistReg.stepOne.yearOfEnding`
                          )
                        "
                        :counter="4"
                        required
                        class="mt-0 pt-1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end mb-10 mt-5">
                    <v-btn
                      @click="validate('education')"
                      v-if="isEditBtn"
                      depressed
                      color="success"
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.save"
                        )
                      }}
                    </v-btn>

                    <v-btn
                      @click="isEditBtn = !isEditBtn"
                      v-if="!isEditBtn"
                      depressed
                      color="warning"
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.update"
                        )
                      }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-form
              :disabled="!isEditBtn"
              ref="form"
              v-model="valid"
              class="mt-5 px-5"
            >
              <v-row>
                <v-col md="6">
                  <p>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.consultingExperience"
                      )
                    }}
                  </p>
                </v-col>
                <v-col md="2" cols="12">
                  <v-text-field
                    v-model="consultingExperience"
                    :rules="consultingExperienceRules"
                    required
                    :label="
                      $vuetify.lang.t(
                        `$vuetify.main.psychologistDashboard.settings.consultingExperienceLabel`
                      )
                    "
                    type="number"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="2">
                  <p>{{ consultingExperienceYearsText }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" cols="12">
                  <p>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.additionalProgram"
                      )
                    }}
                  </p>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="courses"
                    :rules="coursesRules"
                    required
                    :label="
                      $vuetify.lang.t(
                        `$vuetify.main.psychologistDashboard.settings.coursesLabel`
                      )
                    "
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md-3>
                  <p>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.selfTherapy"
                      )
                    }}
                  </p>
                </v-col>
                <v-col md-6>
                  <v-row class="pt-4 pl-3">
                    <span class="justify-center">{{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.no"
                      )
                    }}</span>
                    <v-switch
                      v-model="selfPsychotherapy"
                      class="mt-0 pt-0 ml-2"
                    ></v-switch>
                    <span>{{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.yes"
                      )
                    }}</span>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col md-3>
                  <p>
                    {{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.regularSupervision"
                      )
                    }}
                  </p>
                </v-col>
                <v-col md-6>
                  <v-row class="pt-4 pl-3">
                    <span class="justify-center">{{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.no"
                      )
                    }}</span>
                    <v-switch
                      v-model="regularSupervision"
                      class="mt-0 pt-0 ml-2"
                    ></v-switch>
                    <span>{{
                      $vuetify.lang.t(
                        "$vuetify.main.psychologistDashboard.settings.yes"
                      )
                    }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>

            <div class="d-flex justify-end mb-10 mr-5">
              <v-btn
                @click="validate('experience')"
                v-if="isEditBtn"
                depressed
                color="success"
              >
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.settings.save"
                  )
                }}
              </v-btn>

              <v-btn
                @click="isEditBtn = !isEditBtn"
                v-if="!isEditBtn"
                depressed
                color="warning"
              >
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.settings.update"
                  )
                }}
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-form
              :disabled="!isEditBtn"
              ref="form"
              v-model="valid"
              class="mt-5 px-5"
            >
              <v-col>
                <v-row>
                  <v-col cols="12" md="3" offset-md="1">
                    <p>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.yourCertificates"
                        )
                      }}
                    </p>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-btn
                      color="primary"
                      depressed
                      @click="handleCertificate"
                      small
                      >{{
                        $vuetify.lang.t(
                          "$vuetify.main.psychologistDashboard.settings.uploadDoc"
                        )
                      }}
                    </v-btn>
                    <input
                      type="file"
                      hidden
                      ref="certificate"
                      @change="handleChangeCertificateInput"
                      multiple
                      accept="application/vnd.oasis.opendocument.text ,application/rtf, text/rtf, application/msword, application/pdf,image/png, image/jpg, image/jpeg"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="8" offset-md="4">
                    <v-sheet
                      v-if="this.newCertificates.length > 0"
                      elevation="3"
                      class="mb-8 border"
                    >
                      <v-row>
                        <v-col cols="12" md="8" offset-md="1">
                          <p
                            v-for="(newCertificate, i) in this.newCertificates"
                            :key="i"
                          >
                            {{ newCertificate.name }}
                            <v-btn @click="handleDeleteNewCertificates(i)" icon>
                              <v-icon class="icon-hover">mdi-close</v-icon>
                            </v-btn>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex justify-space-around"
                        >
                          <v-btn
                            @click="handleClearAllNewCertificates"
                            small
                            depressed
                            color="warning"
                            class="mr-2"
                            >{{
                              $vuetify.lang.t(
                                "$vuetify.main.psychologistDashboard.settings.cancel"
                              )
                            }}
                          </v-btn>
                          <v-btn
                            @click="updatePsyhSettings('addCertificate')"
                            small
                            depressed
                            color="primary"
                            >{{
                              $vuetify.lang.t(
                                "$vuetify.main.psychologistDashboard.settings.accept"
                              )
                            }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row class="pt-0">
                  <v-divider></v-divider>
                </v-row>
                <template v-for="(certificate, i) in this.certificates">
                  <v-row :key="i">
                    <v-col cols="12" md="3" offset-md="1">
                      <p class="align-self-center ma-0 pa-0">
                        {{ certificate.title }} {{ i + 1 }}
                      </p>
                    </v-col>
                    <v-col md="2">
                      <v-btn
                        :href="certificate.url"
                        target="_blank"
                        class="ma-0"
                        color="primary"
                        small
                        depressed
                        >{{
                          $vuetify.lang.t(
                            "$vuetify.main.psychologistDashboard.settings.download"
                          )
                        }}
                      </v-btn>
                    </v-col>
                    <v-col md="2">
                      <v-btn
                        class="ma-0"
                        color="warning"
                        depressed
                        small
                        @click="
                          updatePsyhSettings(
                            'deleteCertificate',
                            certificate.title
                          )
                        "
                        >{{
                          $vuetify.lang.t(
                            "$vuetify.main.psychologistDashboard.settings.delete"
                          )
                        }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-form>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";

const TimeZOneInput = () => import("../../../components/UI/timeZoneInput");

const ImageInput = () => import("../../UI/ImageInput");
export default {
  name: "Settings",
  components: {
    TimeZOneInput,
    ImageInput,
  },
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.settings"),
    };
  },
  data() {
    return {
      certificates: [],
      newCertificates: [],
      showAlertForNewAvatar: false,
      avatar: null,
      updatedAvatar: null,
      avatarElement: null,
      rules: {
        required: (value) =>
          !!value ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.rules"
          ),
      },
      tab: null,
      textAreaStep2: "",
      items: [
        {
          tab: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.mainSettings"
          ),
        },
        {
          tab: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.aboutInfo"
          ),
        },
        {
          tab: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.education"
          ),
        },
        {
          tab: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.workExperience"
          ),
        },
        {
          tab: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.certificates"
          ),
        },
      ],
      valid: false,
      psyhName: { ru: "", uk: "" },
      psyhLastName: { ru: "", uk: "" },
      psyhPatronymic: { ru: "", uk: "" },
      psyhEmail: "",
      aboutPsyh: { ru: "", uk: "" },
      timezone: "",
      entrepreneur: {
        status: false,
        requisites: "",
      },
      prevPayload: null,
      formatedDate: "",
      university: "",
      faculty: "",
      speciality: "",
      scienceDegree: [],
      yearOfEnding: "",
      date: "",
      dateTimePicker: "",
      datePickerMenu: false,
      experienceAges: "",
      courses: "",
      experienceWorkingOnline: "",
      selfPsychotherapy: false,
      regularSupervision: false,
      nameRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.nameRules"
          ),
      ],
      lastnameRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.lastnameRules"
          ),
      ],
      emailRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.emailRules1"
          ),
        (v) =>
          /.+@.+/.test(v) ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.emailRules2"
          ),
      ],
      entrepreneurRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.entrepreneurRules1"
          ),
        (v) =>
          v.length >= 10 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.entrepreneurRules2"
          ),
      ],
      consultingExperienceRules: [
        (v) =>
          !!+v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.consultingExperienceRules1"
          ),
        (v) =>
          (v && v >= 0) ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.consultingExperienceRules2"
          ),
        (v) =>
          (v && v <= 100) ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.consultingExperienceRules3"
          ),
      ],
      coursesRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.coursesRules1"
          ),
        (v) =>
          v.length >= 10 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.settings.coursesRules2"
          ),
      ],
      loader: null,
      loading: false,
      isEditBtn: false,
      consultingExperience: "",
      consultingExperienceYearsText: "",
      userPhoneNumber: null,
      defaultCountry: "",
      countriesList: ["UA", "RU", "BY", "MD"],
      translations: {
        countrySelectorLabel: this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.countrySelectorLabel"
        ),
        countrySelectorError: this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.countrySelectorError"
        ),
        phoneNumberLabel: this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.phoneNumberLabel"
        ),
        example: this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.example"
        ),
      },
      hasLoaderActive: false,
      hasErrorActive: false,
      userData: {},
      scienceDegreeList: [
        {
          id: "bachelors",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.bachelors"
          ),
        },
        {
          id: "master",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.master"
          ),
        },
        {
          id: "doctoral",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.doctoral"
          ),
        },
        {
          id: "candidate_of_sciences",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.candidate_of_sciences"
          ),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    getUserAvatarLocalOrFromBuffer: function () {
      return this.user.buffer_photo ? this.user.buffer_photo : this.avatar;
    },
    getWarningIfAvatarChange: function () {
      return !!this.user.buffer_photo;
    },
    universityRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.universityRules"
          ),
      ];
    },
    facultyRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.facultyRules"
          ),
      ];
    },
    specialityRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.specialityRules"
          ),
      ];
    },
    yearOfEndingRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.yearOfEndingRules1"
          ),
        (v) =>
          v.length === 4 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.yearOfEndingRules2"
          ),
      ];
    },
  },
  watch: {
    entrepreneur() {
      // if (!this.entrepreneur.status) {
      //   this.entrepreneur.requisites = ''
      // }
    },
    avatar() {
      if (this.avatarElement) {
        this.handleChangeAvatarView("watch");
      }
    },
    consultingExperience: function () {
      if (
        +this.consultingExperience === 11 ||
        +this.consultingExperience === 12 ||
        +this.consultingExperience === 13 ||
        +this.consultingExperience === 14
      ) {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.years"
        );
      } else if (this.consultingExperience % 10 === 1) {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.year"
        );
      } else if (
        this.consultingExperience % 10 === 2 ||
        this.consultingExperience % 10 === 3 ||
        this.consultingExperience % 10 === 4
      ) {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.years2"
        );
      } else {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.settings.years"
        );
      }
    },
    user: function () {
      this.loadUser();
    },
  },
  mounted() {
    this.$store.dispatch("user/getProfile", {});
    this.loadUser();
  },
  methods: {
    getAvatarElement() {
      this.avatarElement = document.getElementById("img");
      this.handleChangeAvatarView("mount");
    },
    handleChangeAvatarView(type) {
      if (type === "watch") {
        this.avatarElement.src = this.avatar;
      } else {
        this.avatarElement.src = this.getUserAvatarLocalOrFromBuffer;
      }
    },
    loadUser() {
      this.psyhName = this.user.translated.name;
      this.psyhLastName = this.user.translated.surname;
      this.psyhPatronymic = this.user.translated.middle_name || "";
      this.timezone = this.user.timezone;
      this.date = this.user.birthday;
      this.userPhoneNumber = this.user.phone;
      this.defaultCountry = this.user.phone_country_code;
      this.psyhEmail = this.user.email;
      this.aboutPsyh = this.user.translated.about_info;
      this.entrepreneur.requisites = this.user.entrepreneur;
      this.consultingExperience = this.user.experience_ages;
      this.courses = this.user.courses;
      this.experienceWorkingOnline = this.user.experience_working_online;
      this.selfPsychotherapy = this.user.self_psychotherapy;
      this.regularSupervision = this.user.regular_supervision;
      this.avatar = this.user.buffer_photo
        ? this.user.buffer_photo
        : this.user.photo;

      this.university = this.user.education_university;
      this.speciality = this.user.education_speciality;
      this.faculty = this.user.education_faculty;
      this.yearOfEnding = this.user.education_year_of_ending;
      this.scienceDegree = this.user.education_degree_value;

      this.certificates = this.user.certificates;
      if (this.user.entrepreneur) {
        this.entrepreneur.status = true;
      }
    },
    setNewTimeZOne(newTimeZone) {
      this.timezone = newTimeZone;
    },
    getPayload(from, titleDeleteCertificate) {
      let formData = new FormData();
      switch (from) {
        case "main":
          formData.append("phone", this.userData.formattedNumber);
          formData.append("phone_country_code", this.userData.countryCode);
          formData.append("email", this.psyhEmail);
          if (this.updatedAvatar) {
            formData.append("buffer_photo", this.updatedAvatar);
          }
          // eslint-disable-next-line no-case-declarations
          let profile = {
            profile: {
              name: { uk: this.psyhName.uk, ru: this.psyhName.ru },
              surname: { uk: this.psyhLastName.uk, ru: this.psyhLastName.ru },
              middle_name: {
                uk: this.psyhPatronymic.uk,
                ru: this.psyhPatronymic.ru,
              },
              birthday: this.date,
              timezone: this.timezone,
              entrepreneur: this.entrepreneur.status
                ? this.entrepreneur.requisites
                : "",
            },
          };
          // eslint-disable-next-line no-unreachable,no-case-declarations
          return this.buildFormData(formData, profile);
        case "about":
          return {
            profile: {
              about_info: { uk: this.aboutPsyh.uk, ru: this.aboutPsyh.ru },
            },
          };
        case "education":
          return {
            profile: {
              education_university: this.university,
              education_speciality: this.speciality,
              education_faculty: this.faculty,
              education_year_of_ending: this.yearOfEnding,
              education_degree: this.scienceDegree,
            },
          };
        case "experience":
          return {
            profile: {
              experience_ages: this.consultingExperience,
              courses: this.courses,
              self_psychotherapy: this.selfPsychotherapy,
              regular_supervision: this.regularSupervision,
            },
          };
        case "addCertificate":
          return this.buildFormData(formData, {
            new_certificates: this.newCertificates,
          });
        case "deleteCertificate":
          return this.buildFormData(formData, {
            profile: {
              certificate_delete: titleDeleteCertificate,
            },
          });
      }
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
      if (this.newCertificates) {
        this.handleClearAllNewCertificates();
      }
      return formData;
    },
    async updatePsyhSettings(from, titleDeleteCertificate) {
      await this.$store
        .dispatch(
          "user/updateProfile",
          this.getPayload(from, titleDeleteCertificate)
        )
        .then((response) => {
          this.$store.commit("user/UPDATE_USER", response.data);
          Vue.swal({
            target: document.getElementById("main"),
            text: response.data.message,
            icon: "success",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });
          this.isEditBtn = false;
        });
    },
    updatedData(data) {
      this.userData = data;
    },
    validate(from) {
      if (!this.user.main_info_can_be_modified && from !== "about") {
        !this.userData.isValid
          ? this.$refs.form.validate()
          : this.hasErrorActive;
        this.isEditBtn = !this.isEditBtn;

        this.hasErrorActive = !this.userData.isValid;
      }
      this.updatePsyhSettings(from);
    },
    handleChangeAvatar(e) {
      this.updatedAvatar = e.target.files[0];
      this.avatar = URL.createObjectURL(e.target.files[0]);
      this.showAlertForNewAvatar = true;
    },
    handleCertificate() {
      this.$refs.certificate.click();
    },
    handleChangeCertificateInput(event) {
      this.newCertificates = [...event.target.files];
    },
    handleDeleteNewCertificates(index) {
      this.newCertificates.splice(index, 1);
    },
    handleClearAllNewCertificates() {
      this.newCertificates = [];
    },
  },
};
</script>

<style lang="sass" scoped>
.border
  border-radius: 8px
.icon-hover
  &:hover
    color: #e03939

.avatar__grayscale
  filter: grayscale(1)
</style>
